import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const Section = ({ children, color }) => {
  const className = classNames(styles.root, styles[color]);

  return (
    <div className={className}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "white",
    "lightGrey",
    "green",
    "darkGreen",
    "darkGreenGradient",
    "greenWhite",
    "transparent",
  ]),
};

Section.defaultProps = {
  color: "white",
};

export default Section;
