import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { navigate } from "gatsby";
import classnames from "classnames";

import Typography from "root/components/Typography";
import Button from "root/components/Button";
import InputText from "root/components/InputText";

import { motion } from "framer-motion";

import styles from "./index.module.css";

const validateEmail = email => {
  const regularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regularExpression.test(String(email).toLowerCase());
};

const GetStartedEmail = ({ color }) => {
  const [email, setEmail] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [validity, reportValidity] = useState(false);

  const emailRef = useRef();

  const handleInputChange = event => {
    reportValidity(false);

    setEmail(event.target.value);
    setButtonDisabled(!validateEmail(event.target.value));
  };

  const handleGetStartedButtonClick = event => {
    event.preventDefault();

    if (buttonDisabled) {
      reportValidity(true);
    } else {
      navigate("/get-started", {
        state: {
          email,
        },
      });
    }
  };

  const handlePressEnter = event => {
    if (event.keyCode === 13) {
      handleGetStartedButtonClick(event);
    }
  };

  useEffect(() => {
    emailRef.current.addEventListener(
      "invalid",
      event => {
        event.preventDefault();
      },
      true,
    );

    return () => {
      emailRef.current.removeEventListener(
        "invalid",
        event => {
          event.preventDefault();
        },
        true,
      );
    };
  }, []);

  return (
    <div className={styles.root}>
      <div
        className={classnames(
          styles.emailInput,
          buttonDisabled && color === "darkGreen" && styles.disabled,
          validity && styles.validity,
        )}
      >
        <InputText
          ref={emailRef}
          type="email"
          placeholder="Your email address"
          handleKeyDown={handlePressEnter}
          handleOnChange={handleInputChange}
          value={email}
        />
        <Button
          id="myBtn"
          size="medium"
          color={color === "darkGreen" ? "green" : color}
          handleClick={handleGetStartedButtonClick}
        >
          <Typography
            variant="body"
            weight="bold"
            color={color !== "white" ? "white" : "lightGreen"}
          >
            Get Started
          </Typography>
        </Button>
      </div>
      {validity && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 500,
            damping: 300,
            mass: 0.5,
          }}
          className={styles.emailValidation}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 8H12L6 0L0 8Z" fill="white" />
          </svg>
          <div className={styles.validationBox}>
            <Typography variant="smallBody" color="black">
              Please correct your email address
            </Typography>
          </div>
        </motion.div>
      )}
    </div>
  );
};

GetStartedEmail.propTypes = {
  color: PropTypes.oneOf(["green", "darkGreen", "white"]),
};

GetStartedEmail.defaultProps = {
  color: "green",
};

export default GetStartedEmail;
