import React, { Fragment } from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import Typography from "root/components/Typography";
import Button from "root/components/Button";
import SocialIcon from "root/components/SocialIcon";
import GetStartedEmail from "root/components/GetStartedEmail";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.css";

import { NAV_CONTENT } from "./variables";

export const MenuMobile = (
  background,
  currentPage,
  menu,
  dropdown,
  handleDropdown,
) => {
  const lightColor = background === "white" || background === "lightGrey";

  return (
    <AnimatePresence>
      {menu && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: "auto",
          }}
          exit={{ opacity: 0, height: 0 }}
          className={styles.fixed}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        >
          <div className={styles.navContent}>
            {NAV_CONTENT.map(link => {
              const border = !lightColor
                ? styles.whiteBorder
                : styles.blackBorder;

              return link.name === "Other Services" ? (
                <Fragment key={link.name}>
                  <button
                    key={link.name}
                    className={classnames(
                      styles.btnServices,
                      dropdown ? styles.open : border,
                    )}
                    onClick={() => handleDropdown(!dropdown)}
                  >
                    <Typography color={lightColor ? "black" : "white"}>
                      {link.name}
                    </Typography>
                    <div
                      className={classnames(
                        styles.drop,
                        background === "white" &&
                          background === "lightGrey" &&
                          currentPage !== "get-started"
                          ? styles.opacity
                          : null,
                      )}
                    >
                      <svg
                        width="18"
                        height="11"
                        viewBox="0 0 18 11"
                        fill="none"
                        xmlns="httpwww.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L9 9L17 1"
                          stroke={!lightColor ? "white" : "black"}
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </button>
                  <AnimatePresence initial={false}>
                    {dropdown && (
                      <motion.div
                        className={classnames(
                          !lightColor ? styles.whiteBorder : styles.blackBorder,
                        )}
                        key="dropdown"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          transition: {
                            duration: 0.3,
                            ease: "easeIn",
                          },
                        }}
                        exit={{
                          opacity: 0,
                          height: 0,
                          transition: {
                            duration: 0.3,
                            ease: "easeOut",
                          },
                        }}
                      >
                        {link.services.map(service => (
                          <span
                            key={service.route}
                            className={classnames(
                              styles.services,
                              lightColor &&
                                currentPage !== service.route &&
                                currentPage !== "get-started"
                                ? styles.opacity
                                : null,
                            )}
                          >
                            <Link to={`/${service.route}`}>
                              <Typography
                                color={!lightColor ? "white" : "black"}
                                weight={
                                  currentPage === service.route
                                    ? "bold"
                                    : "regular"
                                }
                              >
                                {service.name}
                              </Typography>
                            </Link>
                          </span>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Fragment>
              ) : (
                <div
                  key={link.route}
                  className={classnames(
                    styles.link,
                    !lightColor ? styles.whiteBorder : styles.blackBorder,
                  )}
                >
                  <Link to={`/${link.route}`}>
                    <span
                      className={
                        lightColor &&
                        currentPage !== link.route &&
                        currentPage !== "get-started"
                          ? styles.opacity
                          : null
                      }
                    >
                      <Typography
                        color={!lightColor ? "white" : "black"}
                        weight={currentPage === link.route ? "bold" : "regular"}
                      >
                        {link.name}
                      </Typography>
                    </span>
                    <div className={styles.icon}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 23 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 10.25H15.7751L8.76256 3.23744L11.2374 0.762568L22.4749 12L11.2374 23.2374L8.76256 20.7626L15.7751 13.75H0L0 10.25Z"
                          stroke={!lightColor ? "#0D5D56" : "white"}
                          strokeWidth="1"
                          fill={!lightColor ? "white" : "#0B2027"}
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              );
            })}

            <div className={styles.socialLinks}>
              <SocialIcon
                to="https://www.linkedin.com/company/drivit"
                color={!lightColor ? "white" : "black"}
                type="linkedin"
              />
              <SocialIcon
                to="https://www.facebook.com/drivitapp/"
                color={!lightColor ? "white" : "black"}
                type="facebook"
              />
            </div>
          </div>

          <div
            className={classnames(
              styles.emailInput,
              lightColor ? styles.black : null,
            )}
          >
            <GetStartedEmail color="green" />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const NavbarMobile = (background, currentPage, menu, handleMenu) => {
  const lightColor = background === "white" || background === "lightGrey";
  const styleColor =
    !lightColor && currentPage !== "get-started" ? styles.white : styles.black;

  return (
    <div className={styles.menuMobile}>
      <AnimatePresence>
        {!menu &&
          currentPage !== "get-started" &&
          currentPage !== "blog" &&
          currentPage !== "success" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
            >
              <Link to="/get-started">
                <Button
                  size="medium"
                  color={lightColor ? "lightGreen" : "lightGreenOpacity"}
                >
                  <Typography variant="body" weight="bold" color="white">
                    Get Started
                  </Typography>
                </Button>
              </Link>
            </motion.div>
          )}
      </AnimatePresence>

      <button className={styles.burger} onClick={handleMenu}>
        <motion.div
          className={classnames(styles.burgerLine, styleColor)}
          initial={{
            rotate: 0,
            translateY: 8,
          }}
          animate={menu ? "open" : "closed"}
          variants={{
            open: { rotate: -45, translateY: 0 },
            closed: { rotate: 0, translateY: 8 },
          }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        />
        <motion.div
          className={classnames(styles.burgerLine, styleColor)}
          initial={{
            opacity: 1,
          }}
          animate={menu ? "open" : "closed"}
          variants={{
            open: { opacity: 0 },
            closed: { opacity: 1 },
          }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        />
        <motion.div
          className={classnames(styles.burgerLine, styleColor)}
          initial={{
            rotate: 0,
            translateY: -8,
          }}
          animate={menu ? "open" : "closed"}
          variants={{
            open: { rotate: 45, translateY: 0 },
            closed: { rotate: 0, translateY: -8 },
          }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        />
      </button>
    </div>
  );
};
