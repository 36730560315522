import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import classnames from "classnames";

import Typography from "root/components/Typography";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import logo from "root/assets/flat-logo.svg";
import logoWhite from "root/assets/flat-logo-branco.svg";

import { NavbarDesktop } from "./desktop";
import { NavbarMobile, MenuMobile } from "./mobile";

import styles from "./index.module.css";
import { desktopW, mobileW, FIXED_THRESHOLD } from "./variables";

const Navbar = ({ background, currentPage }) => {
  const [color, setColor] = useState(background);
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [sticky, setSticky] = useState(false);

  const [threshold, setThreshold] = useState(null);
  const dimensions = useWindowDimensions();

  const handleMenuMobile = event => {
    event.preventDefault();

    if (!menu) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.removeAttribute("style");
    }

    setMenu(!menu);
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return () =>
      window.removeEventListener("resize", () => {
        vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
  }, []);

  useEffect(() => () => document.documentElement.removeAttribute("style"), []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window !== null &&
        currentPage !== "get-started" &&
        threshold !== null
      ) {
        const currentScrollY = window.scrollY;
        const diff = currentScrollY != null && currentScrollY > threshold;

        if (diff === true && sticky === false) {
          setSticky(true);
          setColor("white");
        }

        if (diff === false && sticky === true) {
          setSticky(false);
          setColor(background);
        }
      }
    };
    let type;

    if (dimensions.width >= desktopW) type = "desktop";
    else if (dimensions.width > mobileW) type = "tablet";

    if (dimensions.width <= mobileW) type = "mobile";

    if (currentPage === "get-started") {
      if (type === "tablet" || type === "mobile") setColor("white");
      else setColor(background);
    }

    if (type === "desktop" && menu) setMenu(false);

    setThreshold(
      FIXED_THRESHOLD.find(page => page.route === currentPage).device[type],
    );

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [menu, color, threshold, sticky, dimensions]);

  const header = (
    <div className={styles.headerWrapper}>
      <div className={styles.headerContent}>
        <div className={styles.header}>
          <div className={styles.logo}>
            {currentPage === "blog" && (
              <div className={styles.inside}>
                <Typography variant="body" color="black">
                  Inside
                </Typography>
              </div>
            )}
            <Link to="/">
              <img
                className={styles.img}
                src={
                  color !== "white" && color !== "lightGrey" ? logoWhite : logo
                }
                alt="drivit logo"
              />
            </Link>
          </div>

          {NavbarDesktop(color, currentPage, dropdown, setDropdown)}
          {NavbarMobile(color, currentPage, menu, handleMenuMobile)}
        </div>

        {MenuMobile(color, currentPage, menu, dropdown, setDropdown)}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={classnames(
          styles.root,
          styles[color],
          sticky ? styles.headerFixed : styles.headerHidden,
          menu ? styles.menuFull : null,
        )}
      >
        {header}
      </div>
      <div
        className={classnames(
          styles.root,
          styles[color],
          menu ? styles.menuFull : null,
        )}
      >
        {header}
      </div>
    </>
  );
};

Navbar.propTypes = {
  background: PropTypes.oneOf([
    "white",
    "lightGrey",
    "greenWhite",
    "darkGreen",
  ]),
  currentPage: PropTypes.string,
};

Navbar.defaultProps = {
  background: "white",
  currentPage: "home",
};

export default Navbar;
