import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const Button = ({
  children,
  handleClick,
  color,
  type,
  size,
  disabled,
  name,
}) => {
  const className = classNames(styles.button, styles[color], styles[size]);

  return (
    <button
      type={type}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      name={name === "" ? null : name}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
  color: PropTypes.oneOf([
    "none",
    "lightGreen",
    "white",
    "green",
    "lightGrey",
    "lightGreenOpacity",
    "whiteBorderGreen",
  ]),
  type: PropTypes.oneOf(["button", "submit"]),
  size: PropTypes.oneOf(["auto", "medium", "large", "full"]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

Button.defaultProps = {
  handleClick: null,
  color: "white",
  type: "button",
  size: "medium",
  disabled: false,
  name: "",
};

export default Button;
