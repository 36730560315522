import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const InputText = React.forwardRef(
  (
    { placeholder, type, styling, handleOnChange, handleKeyDown, value, name },
    ref,
  ) => {
    const className = classNames(styles.input, styles[styling]);

    return (
      <input
        ref={ref}
        placeholder={placeholder}
        type={type}
        name={name}
        className={className}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
    );
  },
);

InputText.propTypes = {
  handleKeyDown: PropTypes.func,
  handleOnChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  styling: PropTypes.oneOf(["default", "form"]),
  type: PropTypes.string,
  value: PropTypes.string,
};

InputText.defaultProps = {
  handleKeyDown: null,
  handleOnChange: null,
  name: "",
  placeholder: "",
  type: "text",
  styling: "default",
  value: undefined,
};

export default InputText;
