import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const SocialIcon = ({ to, color, size, type }) => {
  const className = classNames(styles[size], styles[type]);

  return (
    <a href={to} rel="noopener noreferrer" target="_blank">
      {type === "facebook" && (
        <svg
          className={className}
          width="14"
          height="28"
          viewBox="0 0 14 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 9.33333H0V14H3.5V28H9.33333V14H13.5823L14 9.33333H9.33333V7.3885C9.33333 6.27433 9.55733 5.83333 10.6342 5.83333H14V0H9.55733C5.362 0 3.5 1.84683 3.5 5.38417V9.33333Z"
            fill={color === "white" ? "#FFFFFF" : "#0B2027"}
          />
        </svg>
      )}
      {type === "linkedin" && (
        <svg
          className={className}
          width="28"
          height="27"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.81 2.91667C5.81 4.52783 4.515 5.83333 2.91667 5.83333C1.31833 5.83333 0.0233333 4.52783 0.0233333 2.91667C0.0233333 1.30667 1.31833 0 2.91667 0C4.515 0 5.81 1.30667 5.81 2.91667ZM5.83333 8.16667H0V26.8333H5.83333V8.16667ZM15.1457 8.16667H9.34967V26.8333H15.1468V17.0345C15.1468 11.5862 22.1807 11.1405 22.1807 17.0345V26.8333H28V15.0138C28 5.8205 17.591 6.15533 15.1457 10.6808V8.16667Z"
            fill={color === "white" ? "#FFFFFF" : "#0B2027"}
          />
        </svg>
      )}
      {type === "twitter" && (
        <svg
          className={className}
          width="28"
          height="23"
          viewBox="0 0 28 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 2.69383C26.9698 3.15117 25.8627 3.45917 24.7007 3.598C25.8872 2.8875 26.7983 1.76167 27.2265 0.42C26.117 1.078 24.8873 1.55633 23.5783 1.81417C22.5318 0.697667 21.0373 0 19.3853 0C15.6765 0 12.9512 3.46033 13.7888 7.0525C9.016 6.81333 4.78333 4.52667 1.9495 1.05117C0.4445 3.633 1.169 7.0105 3.72633 8.72083C2.786 8.6905 1.89933 8.43267 1.12583 8.00217C1.06283 10.6633 2.97033 13.153 5.733 13.7072C4.9245 13.9265 4.039 13.9778 3.13833 13.8052C3.86867 16.0872 5.98967 17.7473 8.505 17.794C6.09 19.6875 3.04733 20.5333 0 20.174C2.54217 21.8038 5.56267 22.7547 8.806 22.7547C19.4717 22.7547 25.4975 13.7468 25.1335 5.66767C26.2558 4.85683 27.23 3.84533 28 2.69383Z"
            fill={color === "white" ? "#FFFFFF" : "#0B2027"}
          />
        </svg>
      )}
    </a>
  );
};

SocialIcon.propTypes = {
  to: PropTypes.string,
  type: PropTypes.oneOf(["facebook", "linkedin", "twitter"]).isRequired,
  color: PropTypes.oneOf(["black", "white"]),
  size: PropTypes.oneOf(["medium", "large"]),
};

SocialIcon.defaultProps = {
  to: null,
  color: "black",
  size: "medium",
};

export default SocialIcon;
