import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import Typography from "root/components/Typography";
import Button from "root/components/Button";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.css";

export const DropdownDesktop = (currentPage, dropdown) => (
  <AnimatePresence initial={false}>
    {dropdown && (
      <motion.div
        className={classnames(
          styles.dropdownWrapper,
          currentPage === "get-started" ? styles.getStarted : null,
        )}
        key="dropdown"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
            ease: "easeIn",
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.3,
            ease: "easeOut",
          },
        }}
      >
        <div className={styles.dropdownContent}>
          <div className={styles.dropdown}>
            <div className={styles.blackBorder}>
              <Link key="/mobility-services" to="/mobility-services">
                <Typography variant="body" color="black">
                  Mobility Services
                </Typography>
              </Link>
            </div>

            <Link key="/how-it-works" to="/how-it-works">
              <Typography variant="body" color="black">
                How It Works
              </Typography>
            </Link>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

export const NavbarDesktop = (
  background,
  currentPage,
  dropdown,
  handleDropdown,
) => {
  const lightColor =
    background === "white" ||
    background === "lightGrey" ||
    background === "greenWhite";

  const textColor = lightColor ? "black" : "white";

  const linkOpacity =
    currentPage === "insurance" ||
    currentPage === "about" ||
    currentPage === "careers";

  return (
    <div className={styles.menuDesktop}>
      <span
        className={classnames(
          styles[textColor],
          lightColor && currentPage !== "insurance" && linkOpacity
            ? styles.opacity
            : null,
        )}
      >
        <Link key="/insurance" to="/insurance">
          <Typography weight={currentPage === "insurance" ? "bold" : "regular"}>
            Insurance
          </Typography>
        </Link>
      </span>

      <button
        className={classnames(
          styles[textColor],
          styles.btnServices,
          dropdown && styles.open,
          lightColor && linkOpacity ? styles.opacity : null,
        )}
        onClick={() => handleDropdown(!dropdown)}
      >
        <Link key="/how-it-works" to="/how-it-works">
          <Typography
            variant="body"
            color="black"
            weight={currentPage === "how-it-works" ? "bold" : "regular"}
          >
            How It Works
          </Typography>
        </Link>
      </button>

      <span className={lightColor && linkOpacity ? styles.opacity : null}>
        <Typography
          color={textColor}
          weight={currentPage === "insurance" ? "bold" : "regular"}
        >
          |
        </Typography>
      </span>

      <span
        className={classnames(
          styles[textColor],
          lightColor && currentPage !== "about" && linkOpacity
            ? styles.opacity
            : null,
        )}
      >
        <Link key="/about" to="/about">
          <Typography weight={currentPage === "about" ? "bold" : "regular"}>
            About
          </Typography>
        </Link>
      </span>

      <span
        className={classnames(
          styles[textColor],
          lightColor && currentPage !== "careers" && linkOpacity
            ? styles.opacity
            : null,
        )}
      >
        <a  href="https://www.zego.com/careers/">
          <Typography weight={currentPage === "careers" ? "bold" : "regular"}>
            Join Us
          </Typography>
        </a>
      </span>

      {currentPage !== "get-started" && currentPage !== "success" ? (
        <Link to="/get-started">
          <Button
            className={styles.btnStarted}
            size="medium"
            color={lightColor ? "lightGreen" : "lightGreenOpacity"}
          >
            <Typography variant="body" weight="bold" color="white">
              Get Started
            </Typography>
          </Button>
        </Link>
      ) : null}
    </div>
  );
};
