export const mobileW = 700;
export const desktopW = 1100;

export const FIXED_THRESHOLD = [
  {
    route: "home",
    device: { desktop: 1150, tablet: 1200, mobile: 950 },
  },
  {
    route: "insurance",
    device: { desktop: 740, tablet: 540, mobile: 470 },
  },
  {
    route: "mobility-services",
    device: { desktop: 0, tablet: 0, mobile: 0 },
  },
  {
    route: "how-it-works",
    device: { desktop: 740, tablet: 780, mobile: 420 },
  },
  {
    route: "blog",
    device: { desktop: 650, tablet: 630, mobile: 740 },
  },
  {
    route: "careers",
    device: { desktop: 300, tablet: 290, mobile: 630 },
  },
  {
    route: "about",
    device: { desktop: 1250, tablet: 1200, mobile: 1030 },
  },
  {
    route: "get-started",
    device: { desktop: 1000, tablet: 0, mobile: 1000 },
  },
  {
    route: "success",
    device: { desktop: 1000, tablet: 0, mobile: 1000 },
  },
  {
    route: "privacy",
    device: { desktop: 100, tablet: 75, mobile: 50 },
  },
  {
    route: "terms",
    device: { desktop: 100, tablet: 75, mobile: 50 },
  },
  {
    route: "position",
    device: { desktop: 100, tablet: 75, mobile: 50 },
  },
];

export const NAV_CONTENT = [
  {
    name: "Insurance",
    route: "insurance",
  },
  {
    name: "How It Works",
    route: "how-it-works",
  },
  {
    name: "About",
    route: "about",
  },
  {
    name: "Join Us",
    route: "careers",
  },
];
