import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Section from "root/components/Section";
import Typography from "root/components/Typography";
import SocialIcon from "root/components/SocialIcon";

import styles from "./index.module.css";

const LINKEDIN = "https://www.linkedin.com/company/drivit";

const SECTIONS = [
  {
    name: "Solutions",
    links: [
      { page: "Insurance", route: "/insurance" },
      { page: "How It Works", route: "/how-it-works" },
    ],
  },
  {
    name: null,
    links: [{ page: "", route: "/" }],
  },
  {
    name: "Company",
    links: [
      { page: "About", route: "/about" }
    ],
  },
];

const Footer = ({ backgroundColor }) => (
  <Section color={backgroundColor}>
    <div className={styles.root}>
      <div className={styles.sections}>
        {SECTIONS.map(({ name, links }) => {
          return (
            <div key={name || "solutions2"} className={styles.section}>
              {name === null ? null : (
                <Typography variant="body" weight="bold">
                  {name}
                </Typography>
              )}
              {links.map(link =>
                link.page === "" ? (
                  <div key={link.route} className={styles.noLink}>
                    <Typography>
                      <Link to={link.route}>{link.page}</Link>
                    </Typography>
                  </div>
                ) : (
                  <div key={link.route} className={styles.link}>
                    <Typography>
                      <Link to={link.route}>{link.page}</Link>
                    </Typography>
                  </div>
                ),
              )}
            </div>
          );
        })}
        <div className={styles.section}>
          <Typography variant="body" weight="bold">
            Find us
          </Typography>
          <div className={styles.socialLinks}>
            <SocialIcon
              size="medium"
              to={LINKEDIN}
              color="black"
              type="linkedin"
            />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.copyright}>
      <div className={styles.legal}>
        <Link to="/privacy">
          <Typography variant="smallBody">Privacy policy</Typography>
        </Link>
        <Link to="/terms">
          <Typography variant="smallBody">Terms of service</Typography>
        </Link>
      </div>
      <div className={styles.inc}>
        <Typography variant="smallBody" color="grey">
          Drivit is a Zego Company
        </Typography>
      </div>
    </div>
  </Section>
);

Footer.propTypes = {
  backgroundColor: PropTypes.oneOf(["white", "lightGrey"]),
};

Footer.defaultProps = {
  backgroundColor: "white",
};

export default Footer;
