import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const Typography = ({ children, variant, weight, color }) => {
  const className = classNames(styles[variant], styles[weight], styles[color]);

  const TextComponent =
    variant === "body" || variant === "smallBody" ? "p" : variant;

  return <TextComponent className={className}>{children}</TextComponent>;
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["h1", "h2", "h3", "body", "smallBody"]),
  weight: PropTypes.oneOf(["regular", "bold"]),
  color: PropTypes.oneOf([
    "white",
    "whiteOpacity",
    "black",
    "grey",
    "darkGrey",
    "green",
    "lightGreen",
    "darkGreen",
  ]),
};

Typography.defaultProps = {
  variant: "body",
  weight: "regular",
  color: "black",
};

export default Typography;
